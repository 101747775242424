<template>
  <div class="container">
    <div class="video" style="oveflow:hidden;">
      <!--<iframe :src="data.link"-->
              <!--allow='autoplay;encrypted-media' allowfullscreen >-->
      <!--</iframe>-->
      <video :src="data.link" controls="controls"></video>
    </div>
  </div>
</template>

<script>
  import {getEnterpriseList} from "../../../api/zoujindaweijia"
  export default {
    name: "Meng",
    data(){
      return{
        data:[]
      }
    },
    mounted() {
      getEnterpriseList().then(res=>{
        // console.log(res.data[0].link);
        let data = res.data[0];
        this.data = data;
      })

    }
  }
</script>

<style scoped>
.container{
  width: 62.4479vw;
  height: 34.6875vw;
  /*border: 0.0521vw solid #c0000d;*/
  margin-left: 17.7776vw;
  margin-bottom: 10.4151vw;
}
  video{
    width: 62.4479vw;
    height: 34.6875vw;
  }
/*iframe{*/
  /*width: 62.4479vw;*/
  /*height: 34.6875vw;*/
/*}*/
</style>